<template>
  <div class="YoutuberDetail" v-if="openYoutuberDetail">
    <v-dialog v-model="openYoutuberDetail" content-class="white-two" max-width="800px"
              @keyup.esc="closeDialog()">
      <v-card class="pa-4">
        <v-card-title class="pa-2">
          <div>
            <v-icon color="default" @click="closeDialog()">close</v-icon>
            <br>
          </div>
        </v-card-title>
        <v-card-text class="pt-0 px-4">
          <v-row class="pt-6 pb-8">
            <v-col cols="3" class="mr-1">
              <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '128' : '64'">
                <img v-if="openYoutuber.thumbnail" :src="openYoutuber.thumbnail"/>
                <img v-else src="@/assets/img/matching-placeholder.jpg">
              </v-avatar>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '8'"
                   :class="$vuetify.breakpoint.mdAndUp ? 'pt-5' : 'pt-2'">
              <v-row>
                <v-col cols="1" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-2'">
                  <!--                    <instaIcon v-if="type === 'insta'"/>-->
                  <youtubeIcon/>
                </v-col>
                <v-col class="subheading">
                  <a :href="'https://www.youtube.com/channel/' + openYoutuber.youtube_id" target="_blank" style="color: #000000">{{ openYoutuber.title }}</a>
                  <v-chip x-small outlined color="#757575">{{openYoutuber.mcn}}</v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-chip x-small outlined color="#34CD89" class="ma-0 mr-1 mt-2"
                        v-for="(category, idx) in _.take(_.sortBy(_.toPairs(openYoutuber.categories), 1).reverse(), 2)"
                        :key="idx">{{ category[0] + ' ' + Math.round(category[1]) + '%' }}
                </v-chip>
              </v-row>
              <v-row>
                <v-list two-line class="pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pt-1">
                      <v-list-item-title style="font-size: 10px; color: #808080">구독자</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{ openYoutuber.subscriberCount | numFormat }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="pt-1">
                      <v-list-item-title style="font-size: 10px; color: #808080">국내 순위</v-list-item-title>
                      <v-list-item-subtitle class="caption">-</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="pt-1">
                      <v-list-item-title style="font-size: 10px; color: #808080">수락률/평점</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{(openYoutuber.acceptRate || '-') + '%'}}/<span style="color:#F0CB30">{{'★' + (openYoutuber.rating || '-')}}</span></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="pt-1">	
                      <v-list-item-title style="font-size: 10px; color: #808080">숏폼 비중</v-list-item-title>	
                      <v-list-item-subtitle class="caption">{{ openYoutuber.shortRate }}%</v-list-item-subtitle>	
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="setFavorite">
              <v-row class="pl-3">
                <v-icon :color="checkFavorite(openYoutuber) ? 'pink' : ''" @click="setFavorite(openYoutuber)">{{checkFavorite(openYoutuber) ? 'mdi-star' : 'mdi-star-outline'}}</v-icon>
              </v-row>
              <v-row>
                즐겨찾기
              </v-row>
            </v-col>
          </v-row>
          <v-tabs v-model="tab">
            <v-tab>채널 통계</v-tab>
            <v-tab>시청자·소재 분석</v-tab>
            <v-tab>영상 통계</v-tab>
            <v-tab>최근 영상</v-tab>
            <v-tab>일별 지표</v-tab>
            <v-tab>유사 채널</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mt-4">
            <v-tab-item>
              <v-row class="youtuber-detail__section font-weight-bold" style="font-size:18px">
                개요
              </v-row>
              <v-row class="pb-8 px-4">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title"><v-icon class="mr-1" small>mdi-account-supervisor-circle</v-icon>구독자 수</div>
                  <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ openYoutuber.subscriberCount | numFormat }}명</div>
                  <div class="mt-2">지난달 대비 <span :style="'color:' + (subscriberCountDiff > 0 ? '#BD1328' : '#4770BD')">{{subscriberCountDiff > 0 ? '+' : ''}}{{subscriberCountDiff | numFormat}}명</span></div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title"><v-icon class="mr-1" small>mdi-play-circle-outline</v-icon>3개월 평균 조회수</div>
                  <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ openYoutuber.avgViewCount3Month | numFormat }}회</div>
                  <div class="mt-2" :style="'color:' + (weeklyHistories[0] ? viewPerSubscriberMap[weeklyHistories[0].avgViewCountGrade].color : '')">
                    <v-icon size="20" :color="weeklyHistories[0] ? viewPerSubscriberMap[weeklyHistories[0].avgViewCountGrade].color : ''">{{weeklyHistories[0] ? viewPerSubscriberMap[weeklyHistories[0].avgViewCountGrade].icon : ''}}</v-icon>
                    {{weeklyHistories[0] ? weeklyHistories[0].avgViewCountGrade : ''}}
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title"><v-icon class="mr-1" small>mdi-cash</v-icon>광고영상 평균 조회수</div>
                  <div v-if="((commercialVideos || []).length > 0 ? Math.round(_.meanBy(commercialVideos, 'viewCount')*10)/10 : 0) > 0" class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ ((commercialVideos ||[]).length > 0 ? Math.round(_.meanBy(commercialVideos, 'viewCount')*10)/10 : 0) | numFormat }}회</div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">집계 중</div>
                </v-col>
              </v-row>
              <v-row class="pb-8 px-4">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title"><v-icon class="mr-1" small>mdi-chart-line</v-icon>최근 3개월 성과</div>
                  <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ openYoutuber.avgViewPerSubscriber12WeeksGrade || '집계 중'}}
                    <v-icon :color="viewPerSubscriberMap[openYoutuber.avgViewPerSubscriber12WeeksGrade].color">{{viewPerSubscriberMap[openYoutuber.avgViewPerSubscriber12WeeksGrade].icon}}</v-icon>
                  </div>
                  <div class="mt-2" :style="'color:' + viewPerSubscriberMap[openYoutuber.avgViewPerSubscriber12WeeksGrade].color">{{Math.round(openYoutuber.avgViewPerSubscriber12Weeks * 10000)/100}}%</div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title"><v-icon class="mr-1" small>mdi-account-search-outline</v-icon>주 시청자층</div>
                  <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{openYoutuber.viewers ? _.sortBy(_.toPairs(openYoutuber.viewers), 1).reverse()[0][0] : '집계 중'}}</div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title"><v-icon class="mr-1" small>mdi-shape-outline</v-icon>가장 많은 소재</div>
                  <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{openYoutuber.subCategories ? _.sortBy(_.toPairs(openYoutuber.subCategories), 1).reverse()[0][0] : '집계 중'}}</div>
                </v-col>
              </v-row>
              <v-row class="pa-4" style="background-color:#FBFBFB; border-radius: 12px;">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">채널 총 조회수</div>
                  <div class="youtuber-detail__subtitle2" style="color: #484848">{{ (openYoutuber.viewCount || '집계 중') | numFormat}}</div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">등록 영상 수</div>
                  <div class="youtuber-detail__subtitle2" style="color: #484848">{{(videos || []).length}}개</div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">최근 업로드</div>
                  <div class="youtuber-detail__subtitle2" style="color: #484848">{{ (videos || []).length > 0 ? ($moment().diff($moment(videos[0].uploadedAt).tz('Asia/Seoul'), 'days') + '일 전') : '집계 중' }}</div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">영상 업로드 빈도</div>
                  <div class="youtuber-detail__subtitle2" style="color: #484848">{{(recentVideos || []).length > 0 ? '월 ' + _.mean(_.map(_.partition(recentVideos, (video) => {
                    return $moment(video.uploadedAt).startOf('month');
                  }), (list) => list.length)) + '개' : '집계 중'}}</div>
                </v-col>
              </v-row>
              <v-row class="youtuber-detail__section font-weight-bold" style="font-size:18px">
                채널 퍼포먼스
              </v-row>
              <v-row class="font-weight-bold px-4" style="font-size: 16px">
                기간별 채널 성과
                <v-tooltip bottom max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="16" color="#4770BD" class="ml-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                  </template>
                  해당 기간동안 유튜버가 등록한 영상의 구독자 대비 조회수 성과입니다. 등록된 영상이 없는 기간의 데이터는 보이지 않습니다.
                </v-tooltip>  
              </v-row>
              <v-row class="mt-2 px-4">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">{{(viewTable.avgViewPerSubscriber1Week || {}).period}}</div>
                  <div v-if="(viewTable.avgViewPerSubscriber1Week || {}).rate > 0">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ viewTable.avgViewPerSubscriber1Week.grade || '집계 중' }}
                      <v-icon :color="viewPerSubscriberMap[viewTable.avgViewPerSubscriber1Week.grade].color">{{viewPerSubscriberMap[viewTable.avgViewPerSubscriber1Week.grade].icon}}</v-icon>
                    </div>
                    <div :style="'color:' + viewPerSubscriberMap[viewTable.avgViewPerSubscriber1Week.grade].color">
                      {{viewTable.avgViewPerSubscriber1Week.rate}}%
                    </div>
                    <div>
                      평균대비 <span :style="'color:' + viewPerSubscriberMap[viewTable.avgViewPerSubscriber1Week.grade].color">{{viewTable.avgViewPerSubscriber1Week.avgRate > 0 ? '+' : ''}}{{viewTable.avgViewPerSubscriber1Week.avgRate}}</span>
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">{{(viewTable.avgViewPerSubscriber4Weeks || {}).period}}</div>
                  <div v-if="(viewTable.avgViewPerSubscriber4Weeks || {}).rate > 0">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{viewTable.avgViewPerSubscriber4Weeks.grade || '집계 중' }}
                      <v-icon :color="viewPerSubscriberMap[viewTable.avgViewPerSubscriber4Weeks.grade].color">{{viewPerSubscriberMap[viewTable.avgViewPerSubscriber4Weeks.grade].icon}}</v-icon>
                    </div>
                    <div :style="'color:' + viewPerSubscriberMap[viewTable.avgViewPerSubscriber4Weeks.grade].color">
                      {{viewTable.avgViewPerSubscriber4Weeks.rate}}%
                    </div>
                    <div>
                      평균대비 <span :style="'color:' + viewPerSubscriberMap[viewTable.avgViewPerSubscriber1Week.grade].color">{{viewTable.avgViewPerSubscriber4Weeks.avgRate > 0 ? '+' : ''}}{{viewTable.avgViewPerSubscriber4Weeks.avgRate}}</span>
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">{{(viewTable.avgViewPerSubscriber12Weeks || {}).period}}</div>
                  <div v-if="(viewTable.avgViewPerSubscriber12Weeks || {}).rate > 0">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ viewTable.avgViewPerSubscriber12Weeks.grade || '집계 중' }}
                      <v-icon :color="viewPerSubscriberMap[viewTable.avgViewPerSubscriber12Weeks.grade].color">{{viewPerSubscriberMap[viewTable.avgViewPerSubscriber12Weeks.grade].icon}}</v-icon>
                    </div>
                    <div :style="'color:' + viewPerSubscriberMap[viewTable.avgViewPerSubscriber12Weeks.grade].color">
                      {{viewTable.avgViewPerSubscriber12Weeks.rate}}%
                    </div>
                    <div>
                      평균대비 <span :style="'color:' + viewPerSubscriberMap[viewTable.avgViewPerSubscriber1Week.grade].color">{{viewTable.avgViewPerSubscriber12Weeks.avgRate > 0 ? '+' : ''}}{{viewTable.avgViewPerSubscriber12Weeks.avgRate}}%</span>
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold">
                    집계 중
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-8 mb-4 px-4 font-weight-bold" style="font-size: 16px">
                일별 퍼포먼스
              </v-row>
              <v-row class="pb-8 px-4">
                <v-col>
                  <v-row class="youtuber-detail__title">구독자 추이</v-row>
                  <v-row>
                    <naverChart :width="700" :height="200" :data="historyChartData"></naverChart>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-4 px-4">
                  <v-row class="youtuber-detail__title">일간 조회수</v-row>
                  <v-row>
                    <naverChart :width="700" :height="200" :data="historyViewChartData"></naverChart>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="youtuber-detail__section font-weight-bold" style="font-size:18px">
                예상 광고 단가
              </v-row>
              <v-row class="px-4 pb-6">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                  <v-row style="color:#484848" class="pb-1">브랜디드 콘텐츠</v-row>
                  <v-row class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">
                    {{ openYoutuber.channelPrice | numFormat }}원
                  </v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                  <v-row style="color:#484848" class="pb-1">기획 PPL</v-row>
                  <v-row class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">
                    {{ (openYoutuber.channelPrice * 0.8) | numFormat }}원
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="px-4">
                <v-col class="pb-4">
                  <v-row class="youtuber-detail__title">예상 광고 단가 추이 (브랜디드 콘텐츠 기준)</v-row>
                  <v-row>
                    <naverChart :width="700" :height="200" :data="historyChannelPriceChartData"></naverChart>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="$route.path !== '/campaign/proposition'">
                  <v-row class="youtuber-detail__section font-weight-bold" style="color: #34CD89;font-weight:700;font-size:16px">이 유튜버에게 제안하기</v-row>
                  <v-row class="mt-4 px-4">
                    <v-col>
                      <v-row style="color:#484848" class="pb-1">예상 광고 단가</v-row>
                      <v-row style="font-size:18px;color:#000000" class="pb-4">{{
                          openYoutuber.channelPrice | numFormat
                        }}원
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row style="color:#484848" class="pb-1">평균 수락 금액</v-row>
                      <v-row style="font-size:18px;color:#000000" class="pb-4">
                        <v-col v-if="$route.path === '/'">
                          <router-link to="/signin" style="font-size:18px" class="secondary--text">
                            가입하고 확인하기
                          </router-link>
                        </v-col>
                        <v-col v-else>
                          {{ (openYoutuber.acceptAvg || 0) | numFormat }}원
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                      <v-row style="color:#484848" class="pb-1">제안 광고비</v-row>
                      <v-row v-if="$route.path === '/'" style="font-size:18px;color:#4770BD" class="pb-4">₩ {{ openYoutuber.adFee | numFormat}}</v-row>
                      <v-row v-else style="font-size:18px;color:#4770BD" class="pb-4" @click="() => {
                          showPointEditDialog=true;
                        }">
                        <v-icon color="secondary" class="edit-button" small>
                          edit
                        </v-icon>
                        {{ (openYoutuber.price || openYoutuber.adFee) | numFormat }}원
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="youtuber-detail__section font-weight-bold" style="font-size:18px">
                시청자 분석
              </v-row>
              <v-row class="px-4">
                <v-col class="pb-4">
                  <v-row class="youtuber-detail__title mb-2">시청자층</v-row>
                  <v-row>
                    <v-col>
                      <pairBarChart :width="600" :height="200" :data="viewersChartData"></pairBarChart>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="youtuber-detail__section font-weight-bold" style="font-size:18px">
                소재 분석 (최근 50개 영상)
              </v-row>
              <v-row class="px-4">
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'" class="pb-8">
                    <v-row class="youtuber-detail__title mb-2">콘텐츠 카테고리</v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '4'">
                        <donutChart :width="144" :height="144" :data="categoryChartData"
                                    :labels="categoryChartLabels"></donutChart>
                      </v-col>
                      <v-spacer :style="$vuetify.breakpoint.mdAndUp ? 'display: none;' : 'display: flex'"></v-spacer>
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'" style="padding-left: 48px">
                        <v-row
                            v-for="(category, idx) in _.union(_.take(_.sortBy(_.toPairs(openYoutuber.categories), 1).reverse(), 5), [categoryEtc])"
                            :key="idx">
                          <v-col class="pa-2" cols="2">
                            <div style="width:12px;height:12px"
                                 :style="'background: ' + ['#DD2333', '#E34956', '#EB717B', '#F29BA2', '#FAC8CC', '#ECEFF1'][idx]"></div>
                          </v-col>
                          <v-col cols="5">{{ category[0] }}</v-col>
                          <v-col cols="5">{{ category[1] | numFormat }}%</v-col>
                        </v-row>
                      </v-col>
                      <v-spacer :style="$vuetify.breakpoint.mdAndUp ? 'display: flex;' : 'display: none'"></v-spacer>
                    </v-row>
                  </v-col>
                </v-row>
              </v-row>
              <v-row class="px-4">
                <v-col class="pb-6">
                  <v-row>
                    <v-col :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'">
                      <v-row class="youtuber-detail__title mb-2">콘텐츠 소재</v-row>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '4'">
                          <donutChart :width="144" :height="144" :data="subCategoryChartData"
                                      :labels="subCategoryChartLabels"></donutChart>
                        </v-col>
                        <v-spacer :style="$vuetify.breakpoint.mdAndUp ? 'display: none;' : 'display: flex'"></v-spacer>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'" style="padding-left: 48px">
                          <v-row
                              v-for="(subCategory, idx) in _.union(_.take(_.sortBy(_.toPairs(openYoutuber.subCategories), 1).reverse(), 5), [subCategoryEtc])"
                              :key="idx">
                            <v-col class="pa-2" cols="2">
                              <div style="width:12px;height:12px"
                                   :style="'background: ' + ['#DD2333', '#E34956', '#EB717B', '#F29BA2', '#FAC8CC', '#ECEFF1'][idx]"></div>
                            </v-col>
                            <v-col cols="5">{{ subCategory[0] }}</v-col>
                            <v-col cols="5">{{ subCategory[1] | numFormat }}%</v-col>
                          </v-row>
                        </v-col>
                        <v-spacer :style="$vuetify.breakpoint.mdAndUp ? 'display: flex;' : 'display: none'"></v-spacer>
                      </v-row>
                      <v-col class="mt-4">
                        <v-row>
                          <v-chip-group v-model="selSubCategory" class="my-2 px-2" mandatory active-class="primary--text">
                            <v-chip small v-for="(subCategory, idx) in _.take(_.sortBy(_.toPairs(openYoutuber.subCategories), 1).reverse(), 5)" :key="idx" :value="subCategory[0]">
                              {{ subCategory[0] + ' '}}{{ subCategory[1] | numFormat }}%
                            </v-chip>
                          </v-chip-group>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-sheet v-dragscroll style="height:160px; display: flex; overflow: scroll;overflow-x:auto; white-space: nowrap;">
                      <a :href="'https://www.youtube.com/watch?v=' + _.filter(videos, (video) => video.subCategory === selSubCategory)[index].youtube_id" target="_blank"
                         v-for="(video, index) in _.filter(videos, (video) => video.subCategory === selSubCategory)" :key="index">
                        <div class="mx-2" style="maxWidth:120px">
                          <img v-if="(video || {}).thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')" width="128px" height="96px" :src="video.thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')"
                               :alt="(video || {}).title" @error="(e) => (videos || []).splice(index, 1)"/>
                          <img v-else src="@/assets/img/matching-placeholder.jpg" style="width:120px;height:68px">
                          <p v-if="(video || {}).title" v-html="(video || {}).title" class="caption ellipsis__3"
                             style="width:120px;color:#484848;white-space:normal;"></p>
                        </div>
                      </a>
                    </v-sheet>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="(openYoutuber.commercialVideos || []).length > 0" class="influencer-detail__title px-4 pb-2">광고 사례</v-row>
              <v-row v-if="(openYoutuber.commercialVideos || []).length > 0" class="px-4">
                <v-sheet v-dragscroll
                         style="height:216px; display: flex; overflow: scroll;overflow-x:auto; white-space: nowrap;">
                  <a :href="'https://www.youtube.com/watch?v=' + commercialVideos[index].youtube_id" target="_blank"
                     v-for="(video, index) in commercialVideos" :key="index">
                    <!--                    v-for="(metadata, index) in openInfluencer.metadata" :key="index">-->
                    <div class="mx-1" style="maxWidth:128px">
                      <img v-if="(video || {}).thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')" width="128px" height="96px" :src="video.thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')"
                           :alt="(video || {}).title" @error="(e) => (commercialVideos || []).splice(index, 1)"/>
                      <img v-else src="@/assets/img/matching-placeholder.jpg" style="width:120px;height:68px">
                      <p v-if="(video || {}).title" v-html="(video || {}).title" class="caption ellipsis__3"
                         style="width:120px;color:#484848;white-space:normal;"></p>
                    </div>
                  </a>
                </v-sheet>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="youtuber-detail__section font-weight-bold" style="font-size:18px">
                영상별 평균 퍼포먼스
              </v-row>
              <v-row class="pb-8 px-4">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">조회수</div>
                  <div v-if="(videos || []).length > 0 && getGrade('avgViewCount', _.meanBy(videos, 'viewCount')).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ ((videos || []).length > 0 ? Math.round(_.meanBy(videos, 'viewCount')) : 0) | numFormat }}회</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgViewCount', _.meanBy(videos, 'viewCount')).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgViewCount', _.meanBy(videos, 'viewCount')).grade].color">{{viewPerSubscriberMap[getGrade('avgViewCount', _.meanBy(videos, 'viewCount')).grade].icon}}</v-icon>
                      {{getGrade('avgViewCount', _.meanBy(videos, 'viewCount')).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">좋아요 수</div>
                  <div v-if="(videos || []).length > 0 && getGrade('avgLikeCount', _.meanBy(videos, 'likeCount')).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ ((videos || []).length > 0 ? Math.round(_.meanBy(videos, 'likeCount')) : 0) | numFormat }}</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgLikeCount', _.meanBy(videos, 'likeCount')).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgLikeCount', _.meanBy(videos, 'likeCount')).grade].color">{{viewPerSubscriberMap[getGrade('avgLikeCount', _.meanBy(videos, 'likeCount')).grade].icon}}</v-icon>
                      {{getGrade('avgLikeCount', _.meanBy(videos, 'likeCount')).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">댓글 수</div>
                  <div v-if="(videos || []).length > 0 && getGrade('avgCommentCount', _.meanBy(videos, 'commentCount')).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ ((videos || []).length > 0 ? Math.round(_.meanBy(videos, 'commentCount')) : 0) | numFormat }}</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgCommentCount', _.meanBy(videos, 'commentCount')).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgCommentCount', _.meanBy(videos, 'commentCount')).grade].color">{{viewPerSubscriberMap[getGrade('avgCommentCount', _.meanBy(videos, 'commentCount')).grade].icon}}</v-icon>
                      {{getGrade('avgCommentCount', _.meanBy(videos, 'commentCount')).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
              </v-row>
              <v-row class="pb-8 px-4">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">예상 CPV</div>
                  <div v-if="(videos || []).length > 0 && getGrade('avgCpv', openYoutuber.channelPrice/_.meanBy(videos, 'viewCount')).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ ((videos || []).length > 0 ? Math.round(openYoutuber.channelPrice/_.meanBy(videos, 'viewCount')) : 0) | numFormat }}원</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgCpv', openYoutuber.channelPrice/_.meanBy(videos, 'viewCount')).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgCpv', openYoutuber.channelPrice/_.meanBy(videos, 'viewCount')).grade].color">{{viewPerSubscriberMap[getGrade('avgCpv', openYoutuber.channelPrice/_.meanBy(videos, 'viewCount')).grade].icon}}</v-icon>
                      {{getGrade('avgCpv', openYoutuber.channelPrice/_.meanBy(videos, 'viewCount')).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">참여율</div>
                  <div v-if="(videos || []).length > 0 && getGrade('avgEngagementPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? ((video.likeCount || 0) + (video.commentCount || 0))*10000/video.viewCount : 0))).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ (videos || []).length > 0 ? Math.round(_.meanBy(videos, (video) => (video.viewCount > 0 ? ((video.likeCount || 0) + (video.commentCount || 0))*10000/video.viewCount : 0)))/100 : 0 }}%</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgEngagementPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? ((video.likeCount || 0) + (video.commentCount || 0))*10000/video.viewCount : 0))).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgEngagementPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? ((video.likeCount || 0) + (video.commentCount || 0))*10000/video.viewCount : 0))).grade].color">{{viewPerSubscriberMap[getGrade('avgEngagementPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? ((video.likeCount || 0) + (video.commentCount || 0))*10000/video.viewCount : 0))).grade].icon}}</v-icon>
                      {{getGrade('avgEngagementPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? ((video.likeCount || 0) + (video.commentCount || 0))*10000/video.viewCount : 0))).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">댓글 작성률</div>
                  <div v-if="(videos || []).length > 0 && getGrade('avgCommentPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? (video.commentCount*10000)/video.viewCount : 0))).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ (videos || []).length > 0 ? Math.round(_.meanBy(videos, (video) => (video.viewCount > 0 ? (video.commentCount*10000)/video.viewCount : 0)))/100 : 0 }}%</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgCommentPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? (video.commentCount*10000)/video.viewCount : 0))).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgCommentPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? (video.commentCount*10000)/video.viewCount : 0))).grade].color">{{viewPerSubscriberMap[getGrade('avgCommentPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? (video.commentCount*10000)/video.viewCount : 0))).grade].icon}}</v-icon>
                      {{getGrade('avgCommentPerViewPercentage', _.meanBy(videos, (video) => (video.viewCount > 0 ? (video.commentCount*10000)/video.viewCount : 0))).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-2 px-4">
                최근 영상
              </v-row>
              <v-row class="px-4">
                <v-sheet v-dragscroll style="height:216px; display: flex; overflow: scroll;overflow-x:auto; white-space: nowrap;">
                  <a :href="'https://www.youtube.com/watch?v=' + videos[index].youtube_id" target="_blank"
                     v-for="(video, index) in videos" :key="index">
                    <div class="mx-1" style="maxWidth:128px">
                      <img v-if="(video || {}).thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')" width="128px" height="96px" :src="video.thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')"
                           :alt="(video || {}).title" @error="(e) => (videos || []).splice(index, 1)"/>
                      <img v-else src="@/assets/img/matching-placeholder.jpg" style="width:120px;height:68px">
                      <p v-if="(video || {}).title" v-html="(video || {}).title" class="caption ellipsis"
                         style="width:120px;color:#484848;white-space:normal;"></p>
                      <div class="mt-1 caption" style="color: #757575">조회수 {{(video || {}).viewCount | numFormat}}</div>
                      <div class="caption" style="color: #757575">{{$moment((video || {}).uploadedAt).format('YYYY. MM. DD')}}</div>
                    </div>
                  </a>
                </v-sheet>
              </v-row>
              <v-row class="youtuber-detail__section font-weight-bold" style="font-size:18px">
                광고 영상 평균 퍼포먼스
              </v-row>
              <v-row class="pb-8 px-4">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">조회수</div>
                  <div v-if="(commercialVideos || []).length > 0 && getGrade('avgCommercialViewCount', _.meanBy(commercialVideos, 'viewCount')).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ ((commercialVideos || []).length > 0 ? Math.round(_.meanBy(commercialVideos, 'viewCount')) : 0) | numFormat }}회</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgCommercialViewCount', _.meanBy(commercialVideos, 'viewCount')).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgCommercialViewCount', _.meanBy(commercialVideos, 'viewCount')).grade].color">{{viewPerSubscriberMap[getGrade('avgCommercialViewCount', _.meanBy(commercialVideos, 'viewCount')).grade].icon}}</v-icon>
                      {{getGrade('avgCommercialViewCount', _.meanBy(commercialVideos, 'viewCount')).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">좋아요 수</div>
                  <div v-if="(commercialVideos || []).length > 0 && getGrade('avgCommercialLikeCount', _.meanBy(commercialVideos, 'likeCount')).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ ((commercialVideos || []).length > 0 ? Math.round(_.meanBy(commercialVideos, 'likeCount')) : 0) | numFormat }}</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgCommercialLikeCount', _.meanBy(commercialVideos, 'likeCount')).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgCommercialLikeCount', _.meanBy(commercialVideos, 'likeCount')).grade].color">{{viewPerSubscriberMap[getGrade('avgCommercialLikeCount', _.meanBy(commercialVideos, 'likeCount')).grade].icon}}</v-icon>
                      {{getGrade('avgCommercialLikeCount', _.meanBy(commercialVideos, 'likeCount')).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">댓글 수</div>
                  <div v-if="(commercialVideos || []).length > 0 && getGrade('avgCommercialCommentCount', _.meanBy(commercialVideos, 'commentCount')).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ ((commercialVideos || []).length > 0 ? Math.round(_.meanBy(commercialVideos, 'commentCount')) : 0) | numFormat }}</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgCommercialCommentCount', _.meanBy(commercialVideos, 'commentCount')).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgCommercialCommentCount', _.meanBy(commercialVideos, 'commentCount')).grade].color">{{viewPerSubscriberMap[getGrade('avgCommercialCommentCount', _.meanBy(commercialVideos, 'commentCount')).grade].icon}}</v-icon>
                      {{getGrade('avgCommercialCommentCount', _.meanBy(commercialVideos, 'commentCount')).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
              </v-row>
              <v-row class="pb-8 px-4">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">예상 CPV</div>
                  <div v-if="(commercialVideos || []).length > 0 && getGrade('avgCommercialCpv', openYoutuber.channelPrice/_.meanBy(commercialVideos, 'viewCount')).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ ((commercialVideos || []).length > 0 ? Math.round(openYoutuber.channelPrice/_.meanBy(commercialVideos, 'viewCount')) : 0) | numFormat }}원</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgCommercialCpv', openYoutuber.channelPrice/_.meanBy(commercialVideos, 'viewCount')).grade].color">
                      <v-icon size="20" :color="viewPerSubscriberMap[getGrade('avgCommercialCpv', openYoutuber.channelPrice/_.meanBy(commercialVideos, 'viewCount')).grade].color">{{viewPerSubscriberMap[getGrade('avgCommercialCpv', openYoutuber.channelPrice/_.meanBy(commercialVideos, 'viewCount')).grade].icon}}</v-icon>
                      {{getGrade('avgCommercialCpv', openYoutuber.channelPrice/_.meanBy(commercialVideos, 'viewCount')).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">참여율</div>
                  <div v-if="(commercialVideos || []).length > 0 && getGrade('avgCommercialEngagementPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.likeCount + video.commentCount)*100/video.viewCount)).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ (commercialVideos || []).length > 0 ? Math.round(_.meanBy(commercialVideos, (video) => (video.likeCount + video.commentCount)*10000/video.viewCount))/100 : 0 }}%</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgCommercialEngagementPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.likeCount + video.commentCount)*100/video.viewCount)).grade].color">
                      <v-icon :color="viewPerSubscriberMap[getGrade('avgCommercialEngagementPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.likeCount + video.commentCount)*100/video.viewCount)).grade].color">{{viewPerSubscriberMap[getGrade('avgCommercialEngagementPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.likeCount + video.commentCount)*100/video.viewCount)).grade].icon}}</v-icon>
                      {{getGrade('avgCommercialEngagementPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.likeCount + video.commentCount)*100/video.viewCount)).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
                  <div class="youtuber-detail__title">댓글 작성률</div>
                  <div v-if="(commercialVideos || []).length > 0 && getGrade('avgCommercialCommentPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.commentCount*100)/video.viewCount)).grade">
                    <div class="youtuber-detail__subtitle font-weight-bold" style="color: #484848">{{ (commercialVideos || []).length > 0 ? Math.round(_.meanBy(commercialVideos, (video) => (video.commentCount*10000)/video.viewCount))/100 : 0 }}%</div>
                    <div class="mt-2" :style="'color:' + viewPerSubscriberMap[getGrade('avgCommercialCommentPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.commentCount*100)/video.viewCount)).grade].color">
                      <v-icon :color="viewPerSubscriberMap[getGrade('avgCommercialCommentPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.commentCount*100)/video.viewCount)).grade].color">{{viewPerSubscriberMap[getGrade('avgCommercialCommentPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.commentCount*100)/video.viewCount)).grade].icon}}</v-icon>
                      {{getGrade('avgCommercialCommentPerViewPercentage', _.meanBy(commercialVideos, (video) => (video.commentCount*100)/video.viewCount)).grade}}
                    </div>
                  </div>
                  <div v-else class="youtuber-detail__subtitle font-weight-bold" style="color: #757575">
                    집계 중
                  </div>
                </v-col>
              </v-row>
              <v-row class="px-4 mb-2">
                최근 광고 영상
              </v-row>
              <v-row class="px-4">
                <v-sheet v-dragscroll style="height:216px; display: flex; overflow: scroll;overflow-x:auto; white-space: nowrap;">
                  <a :href="'https://www.youtube.com/watch?v=' + commercialVideos[index].youtube_id" target="_blank"
                     v-for="(video, index) in commercialVideos" :key="index">
                    <div class="mx-1" style="maxWidth:128px">
                      <img v-if="(video || {}).thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')" width="128px" height="96px" :src="video.thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')"
                           :alt="(video || {}).title" @error="(e) => (commercialVideos || []).splice(index, 1)"/>
                      <img v-else src="@/assets/img/matching-placeholder.jpg" style="width:120px;height:68px">
                      <p v-if="(video || {}).title" v-html="(video || {}).title" class="caption ellipsis"
                         style="width:120px;color:#484848;white-space:normal;"></p>
                      <div class="mt-1 caption" style="color: #757575">조회수 {{(video || {}).viewCount | numFormat}}</div>
                      <div class="caption" style="color: #757575">{{$moment((video || {}).uploadedAt).format('YYYY. MM. DD')}}</div>
                    </div>
                  </a>
                </v-sheet>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="youtuber-detail__subtitle font-weight-bold px-4 mb-4" style="font-size:14px">
                최근 영상
              </v-row>
              <v-row>
                <v-data-table :headers="[{ text: '영상', value:'thumbnail', sortable: false},
                          { text: '제목', value: 'title', sortable: false},
                          { text: '조회수', value: 'viewCount', sortable: true, width: '88px'},
                          { text: '좋아요', value: 'likeCount', sortable: true, width: '88px'},
                          { text: '댓글수', value: 'commentCount', sortable: true, width: '88px'}]"
                              :items="videos"
                              :pagination.sync="pagination"
                              :page="pagination.page"
                              items-per-page="20"
                              hide-default-footer
                              style="backgroud-color: transparent; border-radius: 0; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12); width: 100%">
                  <template v-slot:item.thumbnail="{item}">
                    <a :href="'https://www.youtube.com/watch?v=' + item.youtube_id" target="_blank">
                      <div class="mx-1" style="maxWidth:128px">
                        <img v-if="item.thumbnail || ('https://i.ytimg.com/vi/' + item.youtube_id + '/mqdefault.jpg')" width="120px" height="80px" 
                             :src="item.thumbnail || ('https://i.ytimg.com/vi/' + item.youtube_id + '/mqdefault.jpg')" :alt="item.thumbnail"
                             style="object-fit: cover;"/>
                        <img v-else src="@/assets/img/matching-placeholder.jpg" style="width:120px;height:68px"/>
                      </div>
                    </a>
                  </template>
                  <template v-slot:item.viewCount="{item}">
                    {{item.viewCount | numFormat}}
                  </template>
                  <template v-slot:item.likeCount="{item}">
                    <div v-if="item.likeCount >= 0">
                      {{item.likeCount | numFormat}}
                    </div>
                    <div v-else>
                      비공개
                    </div>
                  </template>
                  <template v-slot:item.commentCount="{item}">
                    <div v-if="item.commentCount >= 0">
                      {{item.commentCount | numFormat}}
                    </div>
                    <div v-else>
                      비공개
                    </div>
                  </template>
                </v-data-table>
                <v-row class="text-center">
                  <v-spacer></v-spacer>
                  <v-pagination
                      class="mt-4"
                      v-model="pagination.page"
                      :length="Math.floor((parseInt((videos || []).length)-1) / 20 + 1)"
                      :total-visible="10"
                  ></v-pagination>
                  <v-spacer></v-spacer>
                </v-row>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="youtuber-detail__subtitle font-weight-bold px-4 mb-4" style="font-size:14px">
                일별 지표
              </v-row>
              <v-row>
                <v-data-table :headers="[{ text: '날짜', value:'crawledAt', sortable: false},
                          { text: '구독자 수', value: 'subscriberCount', sortable: false},
                          { text: '조회수', value: 'viewCount', sortable: false},
                          { text: '영상수', value: 'videoCount', sortable: false}]"
                              :items="dailyHistories"
                              hide-default-footer
                              style="backgroud-color: transparent; border-radius: 0; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12); width: 100%">
                  <template v-slot:item.crawledAt="{item}">
                    {{$moment(item.crawledAt).format('YYYY-MM-DD')}}
                  </template>
                  <template v-slot:item.subscriberCount="{item}">
                    {{item.subscriberCount | numFormat}}
                  </template>
                  <template v-slot:item.viewCount="{item}">
                    {{item.viewCount | numFormat}}
                  </template>
                  <template v-slot:item.videoCount="{item}">
                    {{item.videoCount | numFormat}}
                  </template>
                </v-data-table>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <SimilarityChannel v-if="!!openYoutuber.youtube_id" :id="openYoutuber.youtube_id" :youtuberName="openYoutuber.title"></SimilarityChannel>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="$route.path === '/'" class="rouge" flat @click="openYoutuberDetail=false">
            <router-link to="/signin" class="white-two--text">
              가입하고 제안하기
            </router-link>
          </v-btn>
          <v-menu v-else-if="checkProposition && updateToProposition" v-model="openYoutuber.campaignAddMenu" :close-on-content-click="false" :nudge-width="200" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="rouge" class="white-two--text ma-0 ml-4" tile flat v-bind="attrs" v-on="on"
                     style="font-size:13px">
                제안 목록에 추가
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader style="color:#484848;font-size:13px">다음 캠페인에 추가</v-subheader>
                <v-list-item v-for="(campaign, index) in campaigns.filter((campaign) => (campaign.channel === 'youtube') || campaign.youtube)"
                             @click="updateToProposition(campaign, openYoutuber)" :key="index">
                  <v-list-item-title style="font-size:14px"
                                     :style="checkProposition(campaign, openYoutuber) === 2 ? 'color:#c2c2c2' : 'color:#484848'">
                    {{ campaign.name }}
                    <v-chip outlined small color="#757575" v-if="checkProposition(campaign, openYoutuber) === 2">제안중
                    </v-chip>
                    <v-chip outlined small color="#BD1328" v-else-if="checkProposition(campaign, openYoutuber)">삭제
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPointEditDialog" content-class="white-two" max-width="214px" @keyup.esc="showPointEditDialog=false">
      <v-card class="pa-4">
        <v-card-text class="font-size:13px;color:#484848;">
          제안 금액
          <v-tooltip bottom max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="#4770BD" class="mb-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            예상 광고단가의 80-200% 금액으로 제안하실 수 있습니다.
          </v-tooltip>
          <v-row>
            <v-col cols="9">
              <v-text-field v-model.number="openYoutuber.price" type="number"
                            @input="openYoutuber.price = Math.abs($event)"
                            outlined dense hide-details style="font-size:13px">
              </v-text-field>
            </v-col>
            <v-col class="ml-2 mt-2">
              원
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile class="rouge white-two--text" @click="showPointEditDialog=false">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import campaignMixin from '@/components/shared/campaignMixin'
import naverChart from "@/components/campaign/detail/report/NaverChart";
import donutChart from "@/components/campaign/detail/report/DonutChart";
import pairBarChart from "@/components/campaign/detail/report/PairBarChart";
import SimilarityChannel from './YoutuberDetail/SimilarityChannel.vue';

export default {
  name: 'Youtuberdetail',
  mixins: [campaignMixin],
  props: ['value', 'openYoutuber', 'checkProposition', 'updateToProposition', 'checkFavorite', 'setFavorite', 'statistics'],
  components: {
    youtubeIcon: () => import('@/assets/img/campaign/youtube.svg'),
    naverChart,
    donutChart,
    pairBarChart,
    SimilarityChannel
  },
  data() {
    return {
      tab: null,
      openYoutuberDetail: this.value,
      dailyHistories: [],
      weeklyHistories: [],
      videos: [],
      recentVideos: [],
      commercialVideos: [],
      viewTable: [],
      // statTable: [],
      historyChartData: [],
      historyViewChartData: [],
      historyChannelPriceChartData: [],
      historyVideoViewChartData: [],
      viewersChartData: {},
      categoryChartData: [],
      categoryChartLabels: [],
      categoryEtc: ['기타', 0],
      subCategoryChartData: [],
      subCategoryChartLabels: [],
      subCategoryEtc: ['기타', 0],
      showPointEditDialog: false,
      selSubCategory: '',
      viewPerSubscriberMap: {
        '매우 낮음': {icon: 'mdi-chevron-double-down', color: '#4770BD'},
        '낮음': {icon: 'mdi-chevron-down', color: '#4770BD'},
        '보통': {icon: 'mdi-circle-small', color: '#484848'},
        '높음': {icon: 'mdi-chevron-up', color: '#BD1328'},
        '매우 높음': {icon: 'mdi-chevron-double-up', color: '#BD1328'},
        null: {}
      },
      pagination: {
        page: 1
      }
    }
  },
  computed: {
    subscriberCountDiff() {
      if (this.dailyHistories.length > 0) {
        let lastMonth = this._.find(this.dailyHistories, (history) => {
          return (this.$moment(history.crawledAt).startOf('day').isSame(this.$moment().subtract(1, 'month').startOf('day')));
        });
        return (this.openYoutuber.subscriberCount - (lastMonth ? lastMonth.subscriberCount : 0));
      }
      else {
        return 0;
      }
    }
  },
  watch: {
    value(value) {
      this.openYoutuberDetail = value;
    },
    openYoutuberDetail(value) {
      this.$emit('input', value);
    },
    async openYoutuber(value) {
      this.historyChartData = [];
      this.historyViewChartData = [];
      this.historyChannelPriceChartData = [];
      this.historyVideoViewChartData = [];
      this.categoryChartData = [];
      this.subCategoryChartData = [];

      let videos = await this.axios.get('/youtuber/video?id=' + value.youtube_id);
      this.videos = (videos.data.videos || []);
      this.commercialVideos = this._.filter(this.videos, 'isCommercial') || [];
      this.recentVideos = this._.filter(this.videos, (video) => {
        return (this.$moment().subtract(3, 'month').startOf('day').isAfter(this.$moment(video.uploadedAt).startOf('day')))
      }) || [];
      let dailyHistories = await this.axios.get('/youtuber/daily/history?id=' + value.youtube_id);
      this.dailyHistories = dailyHistories.data.histories;
      let weeklyHistories = await this.axios.get('/youtuber/weekly/history?id=' + value.youtube_id);
      this.weeklyHistories = weeklyHistories.data.histories;

      this.viewTable = {
        avgViewPerSubscriber1Week : {
          period: '최근 1주 성과 (' + this.$moment().subtract(7, 'day').format('YYYY. MM. DD') + '~)'
        },
        avgViewPerSubscriber4Weeks: {
          period: '최근 1개월 성과 (' + this.$moment().subtract(1, 'month').format('YYYY. MM. DD') + '~)'
        },
        avgViewPerSubscriber12Weeks: {
          period: '최근 3개월 성과 (' + this.$moment().subtract(3, 'month').format('YYYY. MM. DD') + '~)'
        }
      };

      this._.keys(this.viewTable).map((field) => {
        let result = this.getGrade(field);
        this.viewTable[field].grade = result.grade;
        this.viewTable[field].rate = result.rate
        this.viewTable[field].avgRate = result.avgRate;
      });

      // this.statTable = [{
      //   name: '최소 조회수', channelValue: (this._.minBy(this.commercialVideos, 'viewCount') || {}).viewCount || 0, channelAvg: '-', diff: '-'
      // }, {
      //   name: '최대 조회수', channelValue: (this._.maxBy(this.commercialVideos, 'viewCount') || {}).viewCount || 0, channelAvg: '-', diff: '-'
      // }, {
      //   name: '평균 반응 수', channelValue: this.commercialVideos.length > 0 ? Math.round(((this._.sumBy(this.commercialVideos, 'commentCount') || 0) + (this._.sumBy(this.commercialVideos, 'likeCount') || 0)) * 100/this.commercialVideos.length)/100 : '-', channelAvg: '-', diff: '-'
      // }, {
      //   name: '댓글 작성률', channelValue: Math.round((this._.sumBy(this.commercialVideos, 'commentCount') * 1000)*100/(this._.sumBy(this.commercialVideos, 'viewCount') || 1))/100, channelAvg: '-', diff: '-'
      // }, {
      //   name: '참여율', channelValue: Math.round(((this._.sumBy(this.commercialVideos, 'commentCount') + this._.sumBy(this.commercialVideos, 'likeCount')) * 1000)*100/(this._.sumBy(this.commercialVideos, 'viewCount') || 1))/100, channelAvg: '-', diff: '-'
      // }, {
      //   name: '예상 CPV', channelValue: this.openYoutuber.channelPrice/((this._.meanBy(this.commercialVideos, 'viewCount') || {}).viewCount || 1), channelAvg: '-', diff: '-'
      // }];

      let yesterday = this._.find(this.dailyHistories, (history) => {
        return (this.$moment(history.crawledAt).startOf('day').isSame(this.$moment().subtract(1, 'day').startOf('day')));
      });
      let beforeYesterday = this._.find(this.dailyHistories, (history) => {
        return (this.$moment(history.crawledAt).startOf('day').isSame(this.$moment().subtract(2, 'day').startOf('day')));
      });
      let lastWeek = this._.find(this.dailyHistories, (history) => {
        return (this.$moment(history.crawledAt).startOf('day').isSame(this.$moment().subtract(8, 'day').startOf('day')));
      });
      value.subscriberDelta = ((yesterday || {}).subscriberCount || 0) - ((lastWeek || {}).subscriberCount || 0);
      value.viewDelta = ((yesterday || {}).viewCount || 0) - ((beforeYesterday || {}).viewCount || 0);

      // let recentWeekHistories = this._.take(this._.orderBy(this.dailyHistories, ['crawledAt'], ['desc']), 8);
      let range = this.$moment().range(this.$moment().startOf('week').subtract(12, 'w'), this.$moment().startOf('week'));
      let dates = Array.from(range.by('weeks'));
      let weekHistories = dates.map((date) => {
        let data = undefined;
        for (let i = 0; i < 12 && !data; i++) {
          date = date.subtract(i, 'd');
          data = this._.find(this.dailyHistories, (history) => {
            return (date.isSame(this.$moment(history.crawledAt).startOf('day')));
          });
        }
        return data
      });
      weekHistories = this._.compact(this._.take(this._.orderBy(weekHistories, ['crawledAt'], ['desc']), 13));

      value.channelPriceDelta = (weekHistories[0] || {}).channelPrice;
      value.videoViewDelta = (this.weeklyHistories[0] || {}).avgViewCount;

      this.historyChartData.push({
        label: '',
        data: this._.take(this.dailyHistories.map((history) => {
          return {
            x: history.crawledAt,
            y: history.subscriberCount
          }
        }), 12),
        backgroundColor: 'rgba(189, 19, 40, .7)',
        hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
        borderColor: 'rgba(189, 19, 40, .7)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        pointBorderWidth: 0,
        fill: false,
        showLine: true
      });

      this.historyViewChartData.push({
        label: '',
        data: this._.take(this.dailyHistories.map((history, idx) => {
          if (idx < (this.dailyHistories.length - 1)) {
            return {
              x: history.crawledAt,
              y: history.viewCount - this.dailyHistories[idx + 1].viewCount
            }
          }
        }), 12),
        backgroundColor: 'rgba(189, 19, 40, .7)',
        hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
        borderColor: 'rgba(189, 19, 40, .7)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        pointBorderWidth: 0,
        fill: false,
        showLine: true
      });
      this.historyChannelPriceChartData.push({
        label: '',
        data: this.dailyHistories.map((history) => {
          if (history.channelPrice) {
            return {
              x: history.crawledAt,
              y: history.channelPrice
            }
          }
          else return null
        }),
        backgroundColor: 'rgba(189, 19, 40, .7)',
        hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
        borderColor: 'rgba(189, 19, 40, .7)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        pointBorderWidth: 0,
        fill: false,
        showLine: true
      });
      this.historyVideoViewChartData.push({
        label: '',
        data: this.weeklyHistories.map((history) => {
          if (history.avgViewCount >=0) {
            return {
              x: history.crawledAt,
              y: history.avgViewCount
            }
          }
          else return null
        }),
        backgroundColor: 'rgba(189, 19, 40, .7)',
        hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
        borderColor: 'rgba(189, 19, 40, .7)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        pointBorderWidth: 0,
        fill: false,
        showLine: true
      });
      let viewers = value.viewers || {};

      let viewersDatasets = [];

      let viewersLabels = this._.keys(viewers).map((viewer) => viewer.replace(' 남성', '').replace(' 여성', ''));
      viewersLabels = this._.uniq(viewersLabels).sort();
      viewersDatasets.push(viewersLabels.map((viewerLabel) => viewers[viewerLabel + ' 남성']));
      viewersDatasets.push(viewersLabels.map((viewerLabel) => viewers[viewerLabel + ' 여성']));
      viewersDatasets[0].unshift(viewersLabels.reduce((acc, cur) => {
        acc += viewers[cur + ' 남성'];
        return acc;
      }, 0))
      viewersDatasets[1].unshift(viewersLabels.reduce((acc, cur) => {
        acc += viewers[cur + ' 여성'];
        return acc;
      }, 0));
      viewersLabels.unshift('전체')

      this.viewersChartData = {
        barPercentage: 1.0,
        // labels: ['전체', '10대', '20대', '30대', '40대', '50대', '60대 이상'],
        labels: viewersLabels,
        datasets: [{
          label: '남성',
          // data: [viewers['10대 남성'] + viewers['20대 남성'] + viewers['30대 남성'] + viewers['40대 남성'] + viewers['50대 남성'] + viewers['60대 이상 남성'], viewers['10대 남성'], viewers['20대 남성'], viewers['30대 남성'], viewers['40대 남성'], viewers['50대 남성'], viewers['60대 이상 남성']],
          data: viewersDatasets[0],
          backgroundColor: '#7CC6FE',
        },
        {
          label: '여성',
          // data: [viewers['10대 여성'] + viewers['20대 여성'] + viewers['30대 여성'] + viewers['40대 여성'] + viewers['50대 여성'] + viewers['60대 이상 여성'], viewers['10대 여성'], viewers['20대 여성'], viewers['30대 여성'], viewers['40대 여성'], viewers['50대 여성'], viewers['60대 이상 여성']],
          data: viewersDatasets[1],
          backgroundColor: '#FF595E',
        }]
      };
      let categories = this._.take(this._.sortBy(this._.toPairs(value.categories), 1).reverse(), 5);
      this.categoryChartData.push({
        data: categories.map((category) => {
          return category[1]
        }),
        backgroundColor: categories.map((category, idx) => {
          return ['#DD2333', '#E34956', '#EB717B', '#F29BA2', '#FAC8CC', '#ECEFF1'][idx];
        })
      });
      this.categoryChartLabels = categories.map((category) => {
        return category[0].padEnd(10, ' ') + category[1].toFixed(1) + '%';
      });
      this.categoryEtc = ['기타', 100 - this._.sum(categories.map((category) => {
        return Math.round(category[1])
      }))];

      let subCategories = this._.take(this._.sortBy(this._.toPairs(value.subCategories), 1).reverse(), 5);
      this.subCategoryChartData.push({
        data: subCategories.map((subCategory) => {
          return subCategory[1]
        }),
        backgroundColor: subCategories.map((subCategory, idx) => {
          return ['#DD2333', '#E34956', '#EB717B', '#F29BA2', '#FAC8CC', '#ECEFF1'][idx];
        })
      });
      this.subCategoryChartLabels = subCategories.map((subCategory) => {
        return subCategory[0].padEnd(10, ' ') + subCategory[1].toFixed(1) + '%';
      });
      this.subCategoryEtc = ['기타', 100 - this._.sum(subCategories.map((subCategory) => {
        return Math.round(subCategory[1])
      }))];
    }
  },
  methods: {
    closeDialog() {
      this.openYoutuberDetail=false;
    },
    getGrade(field, value) {
      let result = {};
      if (field && !this._.isEmpty(this.statistics)) {
        let fieldValue = this._.find(this.statistics, {name: field}).value;
        let channelSection = [1000000, 316227, 100000, 31622, 10000, 3162, 1000, 0];
        let subscriberBoundary = 0;
        channelSection.some((minimum) => {
          if (this.openYoutuber.subscriberCount >= minimum) {
            subscriberBoundary = minimum;
            return true;
          }
          return false;
        });

        let stat = JSON.parse(fieldValue.replace(/\bNaN\b/g, '***NaN***'), function(key, value) {
          return value === "***NaN***" ? NaN : value;
        })[subscriberBoundary];
        if ((value || this.weeklyHistories[0][field]) < stat / 4.0) {
          result.grade = '매우 낮음';
        } else if ((value || this.weeklyHistories[0][field]) > stat / 4.0 && (value || this.weeklyHistories[0][field]) <= stat / 1.6) {
          result.grade = '낮음';
        } else if ((value || this.weeklyHistories[0][field]) > stat / 1.6 && (value || this.weeklyHistories[0][field]) <= stat * 1.6) {
          result.grade = '보통';
        } else if ((value || this.weeklyHistories[0][field]) > stat * 1.6 && (value || this.weeklyHistories[0][field]) <= stat * 4.0) {
          result.grade = '높음';
        } else if ((value || this.weeklyHistories[0][field]) >= stat * 4.0) {
          result.grade = '매우 높음';
        } else {
          result.grade = '';
        }
        result.rate = Math.round((value || this.weeklyHistories[0][field]) * 10000) / 100;
        result.avgRate = Math.round((value || this.weeklyHistories[0][field]) / stat * 10000) / 100;
      }
      return result;
    }
  }
};
</script>

<style scoped lang="scss">
  .youtuber-detail__section {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 18px;
    color: #484848;
    padding: 8px 16px;
    margin: 32px 0 24px;
    border-bottom: #f5f5f5 solid 1px;
  }
  .youtuber-detail__title {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    padding-bottom: 4px;
    margin-bottom: 0;
  }

  .youtuber-detail__subtitle {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    text-align: left;
    font-weight: 300;
    padding-top: 8px;
  }

  .youtuber-detail__subtitle2 {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 20px;
    text-align: left;
    font-weight: 400;
    padding-top: 4px;
  }

  ::v-deep .v-data-table tbody td {
    border: none !important;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .ellipsis__3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  ::v-deep .v-slide-group {
    display: -webkit-box
  }
</style>
<template>
  <div>
    <v-card v-if="youtubeCampaigns.length === 0">
      <img v-if="$vuetify.breakpoint.mdAndUp" src="@/assets/img/proposition/proposal_background-sm.jpg"/>
      <img v-else-if="$vuetify.breakpoint.smOnly" src="@/assets/img/proposition/proposal_background-sm.jpg" style="width: 100%"/>
      <img v-else src="@/assets/img/proposition/proposal_background-xs.jpg"/>
      <v-row align="center" justify="center" class="font-weight-bold" style="color: #484848; font-size:16px">
        아직 등록된 캠페인이 없습니다.
      </v-row>
      <v-row align="center" justify="center" class="my-4 text-center" style="color: #808080; font-size:14px">
        지금 캠페인을 등록하고, 마음에 드는 인플루언서에게<br>
        제안을 보내보세요.
      </v-row>
      <v-row align="center" justify="center" class="py-6">
        <v-btn flat tile color="rouge white--text" to="/regcam/">
          새 캠페인 등록하기
        </v-btn>
      </v-row>
    </v-card>
    <v-card v-else flat class="py-4" style="background-color: #F2F2F2" :style="$vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'">
      <v-row class="pa-4 mb-4" style="background-color: white">
        <v-col md="5" style="font-size:13px;color: #484848;">
          <v-row>
            캠페인
          </v-row>
          <v-row class="mt-2">
            <v-select :items="youtubeCampaigns"
                      item-text="name"
                      item-value="id"
                      :value="((youtubeCampaigns || [])[selectedCampaign] || {}).id"
                      height="40px"
                      hide-details outlined solo flat single-line
                      @change="changeCampaign">
            </v-select>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="propositionsWithIndex"
          item-key="id"
          :items-per-page.sync="options.itemsPerPage"
          :options.sync="options"
          disable-sort
          mobile-breakpoint="0"
          :hide-default-header="$vuetify.breakpoint.smAndDown"
          hide-default-footer
          :footer-props="{
            itemsPerPageOptions: []
          }">
        <template v-slot:header.check="{}">
          <v-icon small class="pl-2" :color="propositionColor()"
                  @click="addToProposeList()">mdi-check-circle</v-icon>
        </template>
        <template v-slot:header.taggableType="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.account="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.Youtuber.subscriberCount="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.Youtuber.channelPrice="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.Youtuber.acceptAvg="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.maxEditCount="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.Youtuber.rating="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.point="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.reward="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          <v-tooltip bottom max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="14" color="#4770BD" style="margin-left:2px" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            회원이 제안을 수락하면 구매 지원금이 회원에게 바로 전달됩니다. 구매 지원금은 세금(부가세 및 원천세) 13.5%가 추가된 금액입니다.
          </v-tooltip>
        </template>
        <template v-slot:header.uploadDesiredDate="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.message="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
  <!--      <template v-slot:header.status="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:header.proposedAt="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>-->
        <template v-slot:header.data-table-expand="{header}">
          <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color:#484848;font-size:13px">
                  {{options.itemsPerPage}}명씩 보기
                  <v-icon small>expand_more</v-icon>
                </v-chip>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in [30, 50, 100]" :key="index" @click="options.itemsPerPage = item">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <div style="background-color: #F2F2F2;width:100%;height:16px">
          </div>
          <div class="body-2 my-4 mx-2" style="color: #808080">
            {{propositionsWithIndex.length}}개 ・ {{Math.floor((parseInt(propositionsWithIndex.length)-1) / options.itemsPerPage + 1)}} Pages
          </div>
          <v-divider></v-divider>
        </template>
        <template v-slot:item.check="{item}">
          <v-row class="pl-2">
            <v-icon :small="$vuetify.breakpoint.mdAndUp" class="mb-1" :color="propositionColor(item)"
                    :disabled="item.status > 0"
                    @click="addToProposeList(item)">mdi-check-circle</v-icon>
    <!--        <v-simple-checkbox v-model="selected[item.index]" :disabled="item.status > 0"></v-simple-checkbox>-->
          </v-row>
          <v-row class="hidden-md-and-up mt-2 pl-2">
            <naverIcon v-if="item.taggableType === 'naver'"/>
            <instaIcon v-if="item.taggableType === 'insta'"/>
            <youtubeIcon v-if="item.taggableType === 'youtube'"/>
          </v-row>
        </template>
        <template v-slot:item.taggableType="props">
          <naverIcon v-if="props.item.taggableType === 'naver'"/>
          <instaIcon v-if="props.item.taggableType === 'insta'"/>
          <youtubeIcon v-if="props.item.taggableType === 'youtube'"/>
        </template>
        <template v-slot:item.account="props">
          <v-row @click="youtuberDetailDlg(props.item.Youtuber)" class="my-2">
            <v-col cols="3">
              <v-avatar size="40">
                <img v-if="props.item.Youtuber.thumbnail" :src="props.item.Youtuber.thumbnail"/>
                <img v-else src="@/assets/img/matching-placeholder.jpg">
              </v-avatar>
            </v-col>
            <v-col cols="9">
              <v-row class="caption" style="color:#4770BD">
                {{props.item.Youtuber.title}}
              </v-row>
              <v-row>
                <v-chip outlined x-small color="#34CD89"
                        class="ma-0 mr-1"
                        v-for="item in Object.keys(props.item.Youtuber.categories || {}).sort(function (a, b) { return props.item.Youtuber.categories[b] - props.item.Youtuber.categories[a]; }).slice(0, 2)" :key="item">
                  {{item}}
                </v-chip>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="hidden-md-and-up mt-2">
            <v-col>
              <v-row style="color:#808080;font-size:10px">
                {{ {naver:'블로그 순위', youtube:'구독자', insta:'팔로워'}['youtube']}}
              </v-row>
              <v-row class="caption" style="color:#808080">
                {{parseInt(props.item.Youtuber.subscriberCount) | numFormat}}
              </v-row>
            </v-col>
            <v-col>
              <v-row style="color:#808080;font-size:10px">
                예상 광고 단가
              </v-row>
              <v-row class="caption" style="color:#808080">
                {{parseInt(props.item.Youtuber.channelPrice) | numFormat}}
              </v-row>
            </v-col>
            <v-col>
              <v-row style="color:#808080;font-size:10px">
                평균 수락 금액
              </v-row>
              <v-row class="caption" style="color:#808080">
                {{props.item.Youtuber.acceptAvg | numFormat}}
              </v-row>
            </v-col>
          </v-row>
          <v-row class="hidden-md-and-up mt-2">
            <v-col>
              <v-row style="color:#808080;font-size:10px">
                평점
              </v-row>
              <v-row class="caption" style="color:#808080">
                <div class="caption" style="color:#808080">
                  <span style="color:#F0CB30">{{'★' + (props.item.Youtuber.rating || '-')}}</span>
                </div>
              </v-row>
            </v-col>
            <v-col>
              <v-row style="color:#808080;font-size:10px">
                수정 가능 횟수
              </v-row>
              <v-row class="caption" style="color:#808080">
                <div class="caption" style="color:#808080">
                  최대 {{props.item.maxEditCount || 0}}회
                </div>
              </v-row>
            </v-col>
            <v-col>
              <v-row style="color:#808080;font-size:10px">
                제안 금액 수정
              </v-row>
              <v-row class="caption" style="color:#4770BD" @click="() => {
                if (props.item.status === 0) {
                  showPointEditDialog=true;
                  editProposition=props.item;
                }
              }">
                <v-icon color="secondary" class="edit-button" small>
                  edit
                </v-icon>
                ₩{{props.item.point | numFormat}}
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.Youtuber.subscriberCount="{item}">
          <v-row class="caption" style="color:#808080">
            {{parseInt(item.Youtuber.subscriberCount) | numFormat}}
          </v-row>
          <v-row style="color:#808080;font-size:10px">
            {{ {naver:'블로그 순위', youtube:'구독자', insta:'팔로워'}['youtube']}}
          </v-row>
        </template>
        <template v-slot:item.Youtuber.channelPrice="{item}">
          <v-row class="caption" style="color:#808080">
            ₩{{parseInt(item.Youtuber.channelPrice) | numFormat}}
          </v-row>
        </template>
        <template v-slot:item.Youtuber.acceptAvg="{item}">
          <v-row class="caption" style="color:#808080">
            ₩{{parseInt(item.Youtuber.acceptAvg) | numFormat}}
          </v-row>
        </template>
        <template v-slot:item.Youtuber.rating="{item}">
          <v-row class="caption" style="color:#808080">
            {{(item.Youtuber.acceptRate || '-') + '%'}}/<span style="color:#F0CB30">{{'★' + (item.Youtuber.rating || '-')}}</span>
          </v-row>
        </template>
        <template v-slot:item.maxEditCount="{item}">
          <v-row class="caption" style="color:#808080">
            최대 {{item.maxEditCount || 0}}회
          </v-row>
        </template>
        <template v-slot:item.point="{item}">
          <v-row class="caption" :style="(item.point >= item.Youtuber.channelPrice * $minYoutubeProposeRate && item.point <= item.Youtuber.channelPrice * $maxProposeRate) ? 'color:#4770BD' : 'color:#BD1328'" @click="() => {
            if (item.status === 0) {
              showPointEditDialog=true;
              editProposition=item;
              if (item.point < item.Youtuber.channelPrice * $minYoutubeProposeRate || item.point > item.Youtuber.channelPrice * $maxProposeRate) {
                editProposition.point = item.Youtuber.channelPrice;
              }
            }
          }">
            <v-icon v-if="(item.point >= item.Youtuber.channelPrice * $minYoutubeProposeRate && item.point <= item.Youtuber.channelPrice * $maxProposeRate)" color="secondary" class="edit-button" small>
              edit
            </v-icon>
            <v-icon v-else color="rouge" small>
              mdi-alert-circle-outline
            </v-icon>
            ₩{{ item.point | numFormat}}
          </v-row>
        </template>
        <template v-slot:item.reward="{item}">
          <v-row v-if="item.cprice && youtubeCampaigns[selectedCampaign].deliveryMethod === 'PR'" class="caption" style="color:#4770BD">
            ₩{{ (item.cprice + item.rewardFeeAmount) | numFormat}}
          </v-row>
          <v-row v-else class="caption" style="color:#4770BD">
            -
          </v-row>
        </template>
        <template v-slot:item.uploadDesiredDate="{item}">
          <v-row class="caption" :style="'color:#4770BD'" @click="() => {
            showUploadDesireDateEditDialog=true;
            uploadDesiredDateEditProposition=item;
            uploadDesiredDateDiff = item.uploadDesiredDate ? $moment(item.uploadDesiredDate).startOf('day').tz('Asia/Seoul').diff($moment().startOf('day').tz('Asia/Seoul'), 'days') : 30;
          }">
            <v-icon color="secondary" class="edit-button" small>
              edit
            </v-icon>
            {{ item.uploadDesiredDate ? $moment(item.uploadDesiredDate).startOf('day').tz('Asia/Seoul').diff($moment().startOf('day').tz('Asia/Seoul'), 'days') : '30'}}일 후
          </v-row>
        </template>
        <template v-slot:item.message="{item}">
          <v-row class="caption" style="color:#808080" @click="() => {
            showMessageDialog=true;
            messageEditProposition=item;
          }">
            <v-icon :color="item.message ? 'secondary' : ''" class="edit-button" small>
              {{item.message ? 'edit' : 'mdi-plus'}}
            </v-icon>
            <span :style="item.message ? 'color:#4770bd' : ''">{{item.message ? '수정' : '추가'}}</span>
          </v-row>
        </template>
        <template v-slot:item.status="{item}">
          <div class="caption" style="color:#808080">
            <div v-if="(item.status || 0) === 1">
              제안
            </div>
            <div v-else-if="(item.status || 0) === 2">
              수락
            </div>
            <div v-else-if="(item.status || 0) === 3">
              거절
            </div>
            <div v-else-if="(item.status || 0) === 4">
              무응답
            </div>
            <div v-else-if="(item.status || 0) === 10">
              발송대기
            </div>
            <div v-else-if="(item.status || 0) === 12">
              미방문
            </div>
            <div v-else-if="(item.status || 0) === 20">
              이용완료
            </div>
            <div v-else-if="(item.status || 0) === 22">
              원고수정요청
            </div>
            <div v-else-if="(item.status || 0) === 24">
              원고확인중
            </div>
            <div v-else-if="(item.status || 0) === 30">
              리뷰대기중
            </div>
            <div v-else-if="(item.status || 0) === 32">
              리뷰완료
            </div>
            <div v-else-if="(item.status || 0) === 50">
              이용취소
            </div>
            <div v-else>
              -
            </div>
          </div>
        </template>
        <template v-slot:item.proposedAt="props">
          <div class="caption" style="color:#808080" v-if="props.item.proposedAt">
            {{$moment(props.item.proposedAt).tz('Asia/Seoul').format('YYYY.MM.DD HH:MM')}}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:footer="">
          <v-row class="mx-2" >
            <v-col cols="8" class="mt-4 text-left">
              <span class="rouge--text font-weight-bold">총 {{selected.filter((select)=>select).length}}건 선택</span>
            </v-col>
            <v-col cols="4" class="text-right">
              <v-btn tile flat outlined color="primary" @click="() => {
                showAllPointEditDialog=true;
              }" :disabled="selected.filter((select)=>select).length === 0" style="width:120px">
                금액 일괄 수정
              </v-btn>
              <v-btn tile flat outlined color="primary" :disabled="selected.filter((select)=>select).length === 0" @click="showRemoveAlert=true" style="width:120px">
                목록에서 지우기
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <div class="text-center" style="background-color: white">
        <v-pagination
            v-model="options.page"
            :length="Math.floor((parseInt(propositionsWithIndex.length)-1) / options.itemsPerPage + 1)"
            :total-visible="10"
        ></v-pagination>
      </div>
      <v-divider color="rouge"></v-divider>
      <div class="pa-4" style="background-color: white">
        <v-row class="mt-4 mx-0">
          <v-col class="subheading text-left" cols="auto" style="color: #757575;width:100px">
            <span style="min-width: 200px">총 인원</span>
          </v-col>
          <v-col>
            <span class="rouge--text font-weight-bold">{{selected.filter((select)=>select).length}}명</span>
          </v-col>
        </v-row>
        <v-row class="mt-4 mx-0" v-if="youtubeCampaigns[selectedCampaign].deliveryMethod === 'PR'">
          <v-col class="subheading text-left" cols="auto" style="color: #757575;width:100px">
            제안 금액
          </v-col>
          <v-col>
            <span class="font-weight-bold" style="color: #757575">₩{{_.sum(selected.map((select, idx)=>{
              if (select) {return propositionsWithIndex[idx].point}
              else 0;
            })) | numFormat}}</span>
          </v-col>
        </v-row>
        <v-row class="mt-4 mx-0" v-if="youtubeCampaigns[selectedCampaign].deliveryMethod === 'PR'">
          <v-col class="subheading text-left" cols="auto" style="color: #757575;width:100px">
            구매 지원금
          </v-col>
          <v-col>
            <span class="font-weight-bold" style="color: #757575">₩{{_.sum(selected.map((select, idx)=>{
              if (select) {return propositionsWithIndex[idx].cprice + propositionsWithIndex[idx].rewardFeeAmount}
              else 0;
            })) | numFormat}}</span>
          </v-col>
        </v-row>
        <v-row class="mx-0 my-4">
          <v-col class="subheading text-left" cols="auto" style="color: #757575;width:100px">
            총 결제금액
          </v-col>
          <v-col>
            <span class="rouge--text font-weight-bold">₩{{_.sum(selected.map((select, idx)=>{
              if (select) {
                if (youtubeCampaigns[selectedCampaign].deliveryMethod === 'PR')
                  return (propositionsWithIndex[idx].point + propositionsWithIndex[idx].cprice + propositionsWithIndex[idx].rewardFeeAmount);
                else
                  return propositionsWithIndex[idx].point;
              }
              else 0;
            })) | numFormat}}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="my-4 body-1" style="color: #757575;">
          제안하기를 진행하면 회원에게 즉시 알림이 발송되므로, 제안 금액과 캠페인이 제대로 설정되었는지 확인해주세요.<br>
          회원이 제안을 거절하거나 수락하지 않고 12시간이 지날 경우 결제 금액은 잔액으로 환불됩니다.<br>
          결제된 제안 금액은 회원이 컨텐츠를 등록하고 일정기간 후 회원에게 지급됩니다.<br>
          구매 지원금은 회원이 제안을 수락하면 영업일 기준 1~2일 이내에 회원에게 즉시 지급됩니다.
        </v-row>
        <v-row class="mx-0">
          <v-col class="mt-4 subheading" :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : 'text-right'" cols="12" md="6" style="color: #757575;">
            <span v-if="improperList.length > 0" class="primary--text">{{improperList.length}}명의 회원의 광고단가가 변경되었습니다. 제안 금액을 변경해주세요.</span>
            <span v-else>위 내용으로 제안을 보내시겠습니까?</span>
          </v-col>
          <v-col class="text-right" cols="12" md="6">
            <v-btn tile color="primary" :disabled="_.compact(selected).length === 0 || improperList.length > 0" @click="showProposeDialog=true;" style="width:120px">
              제안하기
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-row justify="center" align="center">
        <v-dialog v-model="showProposeDialog" content-class="white-two" max-width="500px">
          <v-card class="pa-4">
            <v-card-text class="text-center">
              <div>
                제안 캠페인
              </div>
              <div class="my-2 subheading" style="color: #4770BD">
                {{this.youtubeCampaigns.length > 0 ? this.youtubeCampaigns[this.selectedCampaign].name: ''}}
              </div>
              <v-avatar v-for="(image, index) in selected.map((select, idx)=> {
                if (select) {
                  return propositionsWithIndex[idx].Youtuber.photo;
                }
                return null;
              }).filter((img) => img)" :key="index" class="my-4">
                <img v-if="image" :src="image"/>
                <img v-else src="@/assets/img/matching-placeholder.jpg">
              </v-avatar>
              <div class="mb-2 subheading">총 제안인원 <span style="color: #4770BD">{{_.compact(selected).length}}명</span></div>
              <div class="mb-2 subheading">총 결제금액 <span style="color: #4770BD">₩{{_.sum(selected.map((select, idx)=>{
                  if (select) {
                    if (youtubeCampaigns[selectedCampaign].deliveryMethod === 'PR')
                      return (propositionsWithIndex[idx].point + propositionsWithIndex[idx].cprice + propositionsWithIndex[idx].rewardFeeAmount);
                    else
                      return propositionsWithIndex[idx].point;
                  }
                  else 0;
                })) | numFormat}}</span></div>
              <div class="mt-8 mb-4">
                위 내용으로 제안을 진행하시겠습니까?
              </div>
              <div class="mb-4" style="color: #4770BD">
                제안 후 취소는 불가능합니다.<br>
                제안을 수락한 회원에 대해서만 포인트가 차감됩니다.
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="rouge" tile outlined flat @click="showProposeDialog=false">취소</v-btn>
              <v-btn class="rouge white-two--text" tile flat @click="()=>{
                this.showProposeDialog=false
                this.propose();
              }">제안하기</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <YoutuberDetail v-model="openYoutuberDetail" :openYoutuber="openYoutuber"></YoutuberDetail>
      </v-row>
      <v-row justify="center" align="center">
        <v-dialog v-model="showPointEditDialog" content-class="white-two" max-width="214px">
          <v-card class="pa-4">
            <v-card-text class="font-size:13px;color:#484848;">
              제안 금액
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="#4770BD" class="mb-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                예상 광고단가의 80-200% 금액으로 제안하실 수 있습니다.
              </v-tooltip>
              <v-row>
                <v-col cols="9">
                  <v-text-field v-model.number="editProposition.point" type="number"
                                @input="editProposition.point = Math.round(Math.abs($event))"
                                @keypress="isNumber($event)"
                                outlined dense hide-details style="font-size:13px">
                  </v-text-field>
                </v-col>
                <v-col class="ml-2 mt-2">
                  원
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile class="rouge white-two--text" @click="changePropisitonPoint()">확인</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" align="center">
        <v-dialog v-model="showAllPointEditDialog" content-class="white-two" max-width="264px">
          <v-card class="pa-4">
            <v-card-subtitle style="font-size:13px;padding:0">
              예상 단가 대비 %
            </v-card-subtitle>
            <v-card-text class="font-size:13px;color:#484848;">
              <v-row class="mt-2">
                <v-col cols="6">
                  <v-select v-model="pointEditRate"
                            :items="[{text: '+400%', value: 5}, {text: '+100%', value: 2}, {text: '+70%', value: 1.7}, {text: '+50%', value: 1.5},
                            {text: '+30%', value: 1.3}, {text: '+20%', value: 1.2}, {text: '+10%', value: 1.1},{text: '+0%', value: 1},
                            {text: '-5%', value: 0.95}, {text: '-10%', value: 0.9}, {text: '-20%', value: 0.8}, {text: '-30%', value: 0.7},
                            {text: '-40%', value: 0.6}, {text: '-50%', value: 0.5}]"
                            item-text="text"
                            item-value="value"
                            height="40px"
                            hide-details outlined solo flat single-line dense></v-select>
                </v-col>
                <v-col cols="3">
                  <v-btn text color="primary" @click="pointEditRate=$minYoutubeProposeRate">최소</v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn text color="primary" @click="pointEditRate=$maxProposeRate">최대</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions style="padding: 16px 0 0 0">
              <v-spacer></v-spacer>
              <v-btn tile class="rouge white-two--text" width="80" @click="changePointFromCheckedList()">확인</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" align="center">
        <v-dialog v-model="showInputInfoDialog" content-class="white-two" max-width="272px">
          <v-card class="pa-4">
            <v-card-subtitle style="font-size:13px;padding:0;color:#484848">
              캠페인 정보 등록 안내
            </v-card-subtitle>
            <v-card-text class="font-size:14px;color:#484848;">
              <v-row class="mt-2">
                선택하신 캠페인의 일부 정보가 입력되지 않았습니다. 아래 버튼을 눌러 정보 입력 페이지로 이동하실 수 있습니다.
              </v-row>
            </v-card-text>
            <v-card-actions style="padding: 16px 0 0 0">
              <v-spacer></v-spacer>
              <v-btn color="rouge" tile outlined flat @click="showInputInfoDialog=false" width="80">취소</v-btn>
              <v-btn tile class="rouge white-two--text" width="80" :to="'/campaign/detail/' + selectedCampaign + '?tab=2'">정보입력</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" align="center">
        <v-dialog v-model="showExistDialog" content-class="white-two" max-width="272px">
          <v-card class="pa-4">
            <v-card-subtitle style="font-size:13px;padding:0;color:#484848">
              진행 불가 안내
            </v-card-subtitle>
            <v-card-text class="font-size:14px;color:#484848;">
              <v-row class="mt-2">
                현재 {{existPropostion.map((proposition)=>proposition.Youtuber.account).join(',')}}회원에게 응답 대기중인 제안건이 있어 진행이 불가합니다. 해당 회원을 제외하고 제안을 진행해주세요.
              </v-row>
            </v-card-text>
            <v-card-actions style="padding: 16px 0 0 0">
              <v-spacer></v-spacer>
              <v-btn color="rouge" tile outlined flat @click="showExistDialog=false" width="80">목록으로</v-btn>
              <v-btn tile class="rouge white-two--text" @click="showExistDialog=false" width="80">확인</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" align="center">
        <v-dialog v-model="showRemoveAlert" content-class="white-two" max-width="272px">
          <v-card class="pa-4">
            <v-card-subtitle style="font-size:13px;padding:0;color:#484848">
              목록에서 인플루언서 제거
            </v-card-subtitle>
            <v-card-text class="font-size:14px;color:#484848;">
              <v-row class="mt-2">
                선택하신 {{selected.filter((select)=>select).length}}명의 인플루언서를 목록에서 제거하시겠습니까?
              </v-row>
            </v-card-text>
            <v-card-actions style="padding: 16px 0 0 0">
              <v-spacer></v-spacer>
              <v-btn color="rouge" tile outlined flat @click="showRemoveAlert=false" width="80">취소</v-btn>
              <v-btn tile class="rouge white-two--text" @click="showRemoveAlert=false;delCheckedFromProposeList()" width="80">제거</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" align="center">
        <v-dialog v-model="showUploadDesireDateEditDialog" content-class="white-two" max-width="214px">
          <v-card class="pa-4">
            <v-card-text class="font-size:13px;color:#484848;">
              업로드 희망일
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="#4770BD" class="mb-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                유튜버에게 안내되는 영상 업로드 희망일이며, 진행 과정에 따라 실제 업로드는 희망일보다 늦을 수 있습니다.
              </v-tooltip>
              <v-row>
                <v-col cols="9">
                  <v-text-field v-model.number="uploadDesiredDateDiff" type="number"
                                @input="uploadDesiredDateDiff = Math.round(Math.abs($event))"
                                @keypress="isNumber($event)"
                                outlined dense hide-details style="font-size:13px">
                  </v-text-field>
                </v-col>
                <v-col class="ml-2 mt-2">
                  일
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile class="rouge white-two--text" @click="changeUploadDesiredDate()">확인</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" align="center">
        <v-dialog v-model="showMessageDialog" content-class="white-two" persistent max-width="500px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="showMessageDialog=false">close</v-icon><br>
                <h2 class="mt-2 font-weight-bold subheading" style="color: #484848">제안 메시지</h2>
                <h3 class="body-2" style="color: #757575">유튜버에게 딱 맞는 제안 메시지를 함께 보내 수락률을 높여보세요.</h3>
              </div>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-row >
                <v-col cols="12">
                  <h2 class="font-weight-bold subheading mt-0 mb-2">
                    메시지 내용
                  </h2>
                </v-col>
                <v-col>
                  <v-textarea v-model="messageEditProposition.message" name="message" type="text"
                                v-validate="'required'"
                                :rules="[errors.first('message') || !errors.has('message')]"
                                placeholder="’유튜버’님의 쾌활한 분위기가 저희 브랜드와 잘 맞아 협업 제안을 드리게 되었습니다."
                                hide-details
                                outlined solo flat required></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="rouge" outlined flat @click.native="showMessageDialog=false; ">닫기</v-btn>
              <v-btn class="rouge white-two--text" flat @click="showMessageDialog=false; changeMessage()">저장</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import userMixin from '@/components/shared/userMixin';
  import campaignMixin from '@/components/shared/campaignMixin';
  import pMap from 'p-map';
  import YoutuberDetail from "@/components/campaign/dialog/YoutuberDetail";
  import {EventBus} from "@/components/shared/event-bus";

  export default {
    name: "Propose",
    mixins: [userMixin, campaignMixin],
    props: ['propositions', 'idx'],
    components: {
      naverIcon: () => import('@/assets/img/campaign/naver.svg'),
      instaIcon: () => import('@/assets/img/campaign/insta.svg'),
      youtubeIcon: () => import('@/assets/img/campaign/youtube.svg'),
      YoutuberDetail
    },
    data() {
      return {
        headers_desktop: [
          { text: '', value: 'check'},
          // { text: '채널', value: 'taggableType', width: '52px'},
          { text: '채널 정보', value: 'account', width: '200px' },
          { text: '구독자 수', value: 'Youtuber.subscriberCount'},
          { text: '예상 광고 단가', value: 'Youtuber.channelPrice'},
          { text: '평균 수락 금액', value: 'Youtuber.acceptAvg' },
          { text: '수락률/평점', value: 'Youtuber.rating' },
          { text: '가편집 수정 횟수', value: 'maxEditCount' },
          { text: '제안 금액', value: 'point', width: '110px'},
          { text: '구매 지원금', value: 'reward', width: '110px'},
          { text: '업로드 희망일', value: 'uploadDesiredDate'},
          { text: '제안 메세지', value: 'message'},
        ],
        headers_mobile: [
          { text: '', value: 'check', width: '20px'},
          { text: '채널', value: 'channel', width: '40px'},
          { text: '인플루언서 정보', value: 'account' }
        ],
        selectedCampaign: this.idx || 0,
        selected: [],
        showProposeDialog: false,
        showPointEditDialog: false,
        editProposition: {},
        options: { page: 1, itemsPerPage: 30 },
        openYoutuber: {},
        openYoutuberDetail: false,
        showAllPointEditDialog: false,
        pointEditRate: 1,
        showInputInfoDialog: false,
        showExistDialog: false,
        existPropostion: [],
        showRemoveAlert: false,
        showUploadDesireDateEditDialog: false,
        uploadDesiredDateEditProposition: {},
        uploadDesiredDateDiff: 30,
        showMessageDialog: false,
        messageEditProposition: {}
        // points: [{balance: 0}]
      }
    },
    computed: {
      propositionsWithIndex: {
        cache: false,
        get: function() {
          let propositions = this.propositions.filter((proposition) => {
            return ((proposition.status === 0) && (proposition.campaignId === this.youtubeCampaigns[this.selectedCampaign].id)
                && proposition.taggableType === 'youtube');
          });
          return propositions.map((items, index) => ({
            ...items,
            index: index
          }))
        }
      },
      improperList : {
        cache: false,
        get: function() {
          return (this.selected.filter((select, idx) => {
            return !this.adminMode && select && (this.propositionsWithIndex[idx].point < this.propositionsWithIndex[idx].Youtuber.channelPrice * this.$minYoutubeProposeRate || this.propositionsWithIndex[idx].point > this.propositionsWithIndex[idx].Youtuber.channelPrice * this.$maxProposeRate);
          }))
        }
      },
      headers() {
        return this.$vuetify.breakpoint.mdAndUp ? this.headers_desktop : this.headers_mobile;
      }
    },
    watch: {
    },
    methods: {
      changeCampaign(id) {
        this.youtubeCampaigns.some((campaign, idx) => {
          if (campaign.id === id) {
            this.selectedCampaign = idx;
            this.selected = [];
            // this.$forceCompute('propositionsWithIndex');
            // this.$forceUpdate();
            return true;
          }
          return false
        });
      },
      async propose() {
        //check campaign
        let campaign = this.youtubeCampaigns[this.selectedCampaign];
        let campaignCheck = this.$requiredMap[campaign.category.firstName][campaign.type].every((field) => {
          return campaign[field];
        })
        let channelCheck = this.$requiredMap.youtube[campaign.type].every((field) => {
          if (field === 'mission' || field === 'keywords') {
            return (campaign['youtube'] || {})[field] && (campaign['youtube'] || {})[field].length > 0;
          }
          else if (field === 'reward' || field === 'rewardPrice') {
            return campaign.deliveryMethod !== 'PR' || (campaign.deliveryMethod === 'PR' && (campaign['youtube'] || {})[field]);
          }
          return (campaign['youtube'] || {})[field];
        })
        if (campaignCheck && channelCheck) {
          //check propose
          let query = '';
          this.selected.forEach((select, idx) => {
            if (select) {
              query += '&propositionId[]=' + this.propositionsWithIndex[idx].id
            }
          });

          this.existPropostion = [];
          let pointCheck = await this.axios.get('/company/user/propose?companyId=' + this.user.id + query);
          if (this.selected.some((select, idx) => {
            return (select && (this.propositionsWithIndex[idx].point < this.propositionsWithIndex[idx].Youtuber.channelPrice * this.$minYoutubeProposeRate || this.propositionsWithIndex[idx].point > this.propositionsWithIndex[idx].Youtuber.channelPrice * this.$maxProposeRate));
          }) && !this.adminMode) {
            this.$store.commit('setMsg', '회원의 광고단가가 변경되었습니다. 제안 금액을 변경해주세요.');
            this.$store.commit('setShowSnackbar', true);
          }
          else if (this.selected.some((select, idx) => {
            let proposition = this._.find(this.propositions, {
              'campaignId': this.propositionsWithIndex[idx].campaignId,
              'taggableId': this.propositionsWithIndex[idx].taggableId,
              'taggableType': this.propositionsWithIndex[idx].taggableType,
              'status': 1});
            if (select && proposition) {
              this.existPropostion.push(proposition);
              return true;
            }
            return false;
          })) {
            this.showExistDialog = true;
          }
          else if (pointCheck.data.balance >= pointCheck.data.points) {
            await pMap(this.selected, (select, idx) => {
              if (select) {
                let fees = this.propositionsWithIndex[idx].fees || 30;
                let settlementAmount = Math.floor(this.propositionsWithIndex[idx].point * (1 - (fees / 100)) / 1000) * 1000;
                let data = {
                  status: 1,
                  proposedAt: new Date(),
                  cname: campaign.serviceName,
                  creward: campaign[this.propositionsWithIndex[idx].taggableType].reward,
                  cprice: campaign[this.propositionsWithIndex[idx].taggableType].rewardPrice,
                  category: campaign.category,
                  thumbnail: campaign.thumbnail,
                  periodUse: this.$periodMap[this.propositionsWithIndex[idx].taggableType][campaign.type][campaign.category.firstName][0],
                  periodFirstCopy: this.$periodMap[this.propositionsWithIndex[idx].taggableType][campaign.type][campaign.category.firstName][1],
                  fees: fees,
                  feeAmount: this.propositionsWithIndex[idx].point - settlementAmount,
                  settlementAmount: settlementAmount,
                  uploadDesiredDate: this.propositionsWithIndex[idx].uploadDesiredDate || this.$moment().tz('Asia/Seoul').add(30, 'day').startOf('day').toDate()
                }
                if (campaign[this.propositionsWithIndex[idx].taggableType].rewardPrice && campaign.deliveryMethod === 'PR') {
                  data.rewardFees = 13.5;
                  data.rewardFeeAmount = campaign[this.propositionsWithIndex[idx].taggableType].rewardPrice * data.rewardFees * 0.01;
                }
                return this.axios.post('/company/user/propose?id=' + this.propositionsWithIndex[idx].id, data);
              }
              return;
            }, {concurrency: 1})
            if (this.user) {
              EventBus.$emit("update:point");

              await this.axios.post('utils/sendsms', {
                phoneNumber: this.user.contacts.replace(/\D/g, ''),
                contents: "[슈퍼차트] 광고 제안 완료\n" +
                    this.youtubeCampaigns[this.selectedCampaign].name + ' 광고 제안이 완료되었습니다.\n' +
                    ((this.youtubeCampaigns[this.selectedCampaign].category.firstName === '매장' && this.youtubeCampaigns[this.selectedCampaign].type === 'T') ? '진행여부 확정 후 슈퍼차트 회원으로 예약문의가 시작될 예정입니다.' : '12시간 이내 진행여부가 확정됩니다.')
              });
              await this.axios.post('utils/sendslack', {
                channel: 'superchart',
                contents: '[슈퍼차트] 광고 제안 완료/' + this.youtubeCampaigns[this.selectedCampaign].id + '/' + this.youtubeCampaigns[this.selectedCampaign].name + '/LMS'
              });
            }
            this.$router.go(0);
          } else {
            this.$store.commit('setMsg', '잔액이 부족합니다.');
            this.$store.commit('setShowSnackbar', true);
          }
        }
        else {
          this.showInputInfoDialog = true;
        }
      },
      propositionColor(item) {
        if (item) {
          return ((this.selected[item.index] && item.status === 0) ? 'rouge' : '');
        }
        else {
          return ((this.selected.filter(Boolean).length > 0) ? 'rouge' : '');
        }
      },
      addToProposeList(item) {
        if (item) {
          if (item.status === 0) {
            this.selected[item.index] = !this.selected[item.index];
            this.$forceUpdate();
          }
        }
        else {
          this.propositionsWithIndex.map((proposition) => {
            this.selected[proposition.index] = !this.selected[proposition.index];
          });
          this.$forceUpdate();
        }
      },
      async delCheckedFromProposeList() {
        await Promise.all(this.selected.map((select, idx) => {
          if (select) {
            return this.axios.delete('/campaign/proposition?id=' + this.propositionsWithIndex[idx].id);
          }
          return;
        }))
        this.$router.go(0);
      },
      async changePropisitonPoint() {
        let maxEditCount = this.$getMaxEditCount(this.editProposition.point, 'youtube');
        if (!this.adminMode) {
          if (this.editProposition.point < Math.round(this.editProposition.Youtuber.channelPrice * this.$minYoutubeProposeRate)) {
            this.$store.commit('setMsg', `해당 회원의 최소 제안가격은 ${Math.round(this.editProposition.Youtuber.channelPrice * this.$minYoutubeProposeRate)}원 입니다`);
            this.$store.commit('setShowSnackbar', true);
            this.editProposition.point = Math.round(this.editProposition.Youtuber.channelPrice * this.$minYoutubeProposeRate);
          } else if (this.editProposition.point > (this.editProposition.Youtuber.channelPrice * this.$maxProposeRate)) {
            this.$store.commit('setMsg', `해당 회원의 최대 제안가격은 ${this.editProposition.Youtuber.channelPrice * this.$maxProposeRate}원 입니다`);
            this.$store.commit('setShowSnackbar', true);
            this.editProposition.point = this.editProposition.Youtuber.channelPrice * this.$maxProposeRate;
          }
        }
        await this.axios.put('/campaign/proposition?id=' + this.editProposition.id, {
          point: this.editProposition.point,
          maxEditCount: maxEditCount
        });
        this.showPointEditDialog = false;
        window.setTimeout(() => {
          this.$emit('updateProposition');
        }, 1000);
      },
      isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode < 48 || charCode > 57) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      async youtuberDetailDlg(item, event) {
        console.log(event)
        this.openYoutuberDetail = true;
        this.openYoutuber = item;
      },
      async changePointFromCheckedList() {
        await Promise.all(this.selected.map(async (select, idx) => {
          if (select) {
            let proposition = this.propositionsWithIndex[idx];
            proposition.maxEditCount = this.$getMaxEditCount(proposition.point);
            proposition.point = proposition.Youtuber.channelPrice * this.pointEditRate;
            await this.axios.put('/campaign/proposition?id=' + proposition.id, {
              point: proposition.point,
              maxEditCount: proposition.maxEditCount
            });
          }
          return;
        }));

        window.setTimeout(() => {
          this.$emit('updateProposition');
        }, 1000);

        this.showAllPointEditDialog = false;
        this.$store.commit('setMsg', '제안 금액 수정이 완료되었습니다.');
        this.$store.commit('setShowSnackbar', true);
      },
      async changeUploadDesiredDate() {
        if (this.uploadDesiredDateDiff >= 0) {
          this.uploadDesiredDateEditProposition.uploadDesiredDate = this.$moment().tz('Asia/Seoul').add(this.uploadDesiredDateDiff, 'day').startOf('day').toDate();
          await this.axios.put('/campaign/proposition?id=' + this.uploadDesiredDateEditProposition.id, {
            uploadDesiredDate: this.uploadDesiredDateEditProposition.uploadDesiredDate,
          });
          this.showUploadDesireDateEditDialog = false;
          window.setTimeout(() => {
            this.$emit('updateProposition');
          }, 1000);
        }
      },
      async changeMessage() {
        await this.axios.put('/campaign/proposition?id=' + this.messageEditProposition.id, {
          message: this.messageEditProposition.message
        });
        this.showMessageDialog = false;
        window.setTimeout(() => {
          this.$emit('updateProposition');
        }, 1000);
      },
    },
    async created() {
      EventBus.$emit("update:point");
    }
  }
</script>

<style scoped lang="scss">
  .edit-button {
    pointer-events: auto;
  }

  ::v-deep .v-expansion-panel__header {
    padding: 0px;
  }

  .edit-button {
    pointer-events: auto;
  }

  ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
  ::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    padding: 0 8px;
  }
</style>
<script>
  import {Bar} from 'vue-chartjs'

  export default {
    name: "BarChart",
    extends: Bar,
    props: ['data'],
    watch: {
      data() {
        this.displayChart();
      }
    },
    methods: {
      displayChart() {
        this.renderChart(this.data, {
          legend: {
            display: false
          },
          scales: {
            scaleShowValues: true,
            xAxes: [{
              ticks: {
                autoSkip: false
              },
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                callback: function (value) {
                  return value + '%'
                }
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                return Math.round(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] * 10) /10 + '%';
              }
            }
          }
        })
      }
    },
    mounted() {
      this.displayChart();
    }
  }
</script>

<style scoped lang="scss">

</style>